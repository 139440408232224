import messages from './locale/index';
import { createSSRApp, getCurrentInstance } from 'vue';
import App from './App.vue';
import { Pinia, myPinia } from '@stores/index.js';
import { createI18n } from 'vue-i18n';
import uviewPlus from '@/uni_modules/uview-plus';
import zConfig from '@/uni_modules/z-paging/components/z-paging/js/z-paging-config';
import { setupGlobalStat } from '@/plugins/Stat/index.ts';
import { default as config } from '@config';
import eruda from 'eruda';
import VConsole from 'vconsole';
import { Buffer } from 'buffer';
import Skeleton from './components/Skeleton/Skeleton.vue';

window.Buffer = Buffer;

if (config._env == 'test') {
  var vConsole = new VConsole();
  eruda.init();
}

let language = uni.getLocale();
console.log('--i18n:', language);
// #ifdef H5
// language = uni.getLocale() || navigator.languages[1];
// #endif
// #ifdef APP-PLUS
// #endif

let i18nConfig = {
  locale: language,
  messages,
};
const i18n = createI18n(i18nConfig);

// const { proxy } = getCurrentInstance();
if (config._env === 'release') {
  console.log = () => {};
}

zConfig.setConfig({
  //配置分页默认pageSize为15
  // "width": "750rpx",
  // `${proxy.$t('recharge.rechargeMore')}`;
  'default-page-size': '10',
  // 'empty-view-text': 'Vacío',
  // 'loading-more-no-more-text': '¡Tengo límites!',
  // 'refresher-default-text': 'Desplegable para actualizar',
  // 'refresher-pulling-text': 'Liberar para refrescarse inmediatamente',
  // // 'refresher-refreshing-text': 'Actualizando',

  // "empty-view-text": "空空如也~~",
  // "loading-more-no-more-text": "我也是有底线的！",
  // "refresher-default-text": "下拉可刷新",
  // "refresher-pulling-text": "释放立即刷新",
  // "refresher-refreshing-text": "正在刷新",
});

// window?.addEventListener('beforeinstallprompt', function () {
//   console.error('++++++beforeinstallprompt');
// });

// #ifdef H5
function beforeinstallpromptFun(e) {
  // 阻止 Chrome 67 及更早版本自动显示提示
  console.log('main |-|-| beforeinstallprompt event');
  e.preventDefault();
  uni.$emit('update1', e);
  return false;
}
window?.addEventListener('beforeinstallprompt', beforeinstallpromptFun);

// #endif
export function createApp() {
  const app = createSSRApp(App);
  app.component('Skeleton', Skeleton);
  app.use(i18n);
  app.use(myPinia);
  app.use(uviewPlus);
  setupGlobalStat(app);
  return {
    app,
    Pinia,
  };
}
